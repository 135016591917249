import React from "react";
// import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <>
      <footer className="footer-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 text-center">
              <div className="footer-items">
                <div className="social-icons d-flex justify-content-center my-4">
                  <a
                    className="facebook"
                    href="https://www.facebook.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-social-facebook"></i>
                    <i className="icon-social-facebook"></i>
                  </a>
                  <a
                    className="twitter"
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-social-twitter"></i>
                    <i className="icon-social-twitter"></i>
                  </a>
                  <a
                    className="linkedin"
                    href="https://www.linkedin.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-social-linkedin"></i>
                    <i className="icon-social-linkedin"></i>
                  </a>
                  <a
                    className="reddit"
                    href="https://www.reddit.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-social-reddit"></i>
                    <i className="icon-social-reddit"></i>
                  </a>
                  <a
                    className="vkontakte"
                    href="https://discord.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-social-vkontakte"></i>
                    <i className="icon-social-vkontakte"></i>
                  </a>
                  <a
                    className="youtube"
                    href="https://www.youtube.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-social-youtube"></i>
                    <i className="icon-social-youtube"></i>
                  </a>
                </div>
                <div className="copyright-area py-2">
                  &copy;2022 0xLaunch, All Rights Reserved By 0xLaunch
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
